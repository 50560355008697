<template>
  <v-container class="">
    <v-row>
      <v-col>
        <h2 class="display-2">Registro de Sobrecargos</h2>
        <hr class="red" />
        <h3 class="display-1" v-if="this.currentUser.solicitud.folio">
          Folio:
          <strong class="blue-grey--text text--darken-1">
            {{ this.currentUser.solicitud.folio }}
          </strong>
        </h3>
      </v-col>
    </v-row>

    <!---<v-row>
      <pre>{{
        $CryptoJS.AES.encrypt(
          "Befh880820",
          "EYMpHQlhOG1JN3ubfH06Ir5eXhypLVmm"
        ).toString()
      }}</pre>
      <pre>
        {{
          $CryptoJS.AES.decrypt(
            "U2FsdGVkX1/Qnz2GBplUgAfC+7ePRN+S3/ca6LONjEY=",
            "EYMpHQlhOG1JN3ubfH06Ir5eXhypLVmm"
          ).toString()
        }}
      </pre>
    </v-row>-->
    <v-row>
      <v-col cols md="3">
        <v-btn dark x-large color="primary"  to="/flight-attendant/personal-data" block>
          Datos Generales
        </v-btn>
      </v-col>
      <v-col cols md="3">
        <v-btn dark x-large color="primary" to="/flight-attendant/documents" block>
          Documentos
        </v-btn>
      </v-col>
      <v-col cols md="3">
        <v-btn dark x-large color="primary" to="/flight-attendant/sign" block>
          Firma
        </v-btn>
      </v-col>
      <v-col cols md="3">
        <v-btn dark x-large color="primary" to="/flight-attendant/resignation" block>
          Otros documentos
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols md="12" class="rounded-lg grey lighten-5 mt-6 pa-8 elevation-15 mb-16">
        <router-view :estatus="currentUser.solicitud.estatus" :folio="currentUser.solicitud.folio"></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import FlightAttendantDatosPersonalesComponent from "@/components/FlightAttendant/DatosPersonales";
//import FlightAttendantDatosLaboralesComponent from "@/components/FlightAttendant/DatosLaborales";
//import FlightAttendantDocumentosComponent from "@/components/FlightAttendant/Documentos";
//import FlightAttendantCartaRenunciaComponent from "@/components/FlightAttendant/CartaRenuncia";
import GlobalAlerts from "@/components/global/_Alerts.vue";
import User from "@/models/user";

export default {
  name: "FlightAttendant",
  components: {
    GlobalAlerts,
    //FlightAttendantDatosPersonalesComponent,
    //FlightAttendantDatosLaboralesComponent,
    //FlightAttendantDocumentosComponent,
    //FlightAttendantCartaRenunciaComponent,
  },

  data() {
    return {
      tab: null,
      valid: true,
      completeRegister: false,
      persona: "Guillermo Israel Garcia Guerrero",
      user: new User("", ""),
      loader: null,
      loading: false,
      message: "",
      valid: true,
      showpass: false,
      errorStatus: "",
      currentImage: undefined,
      previewImage: undefined,
      uploadFile: false,
      alertWarning: false,
      loading: false,
      loaded: false,
      toggle_exclusive: 1,

      documentos: {
        documentoIdentificacion: [],
        documentoCredencial: [],
        documentoCurp: [],
        firmaAutografa: [],
      },

      dataForm: {
        nombre: "",
        primerApellido: "",
        segundoApellido: "",
        correoElectronico: "",
        curp: "",
        telefonoContacto: "",
        nombreEmpresa: "",
        numeroEmpleado: "",
        actividadRealizada: "",
        documentoIdentificacion: [],
        documentoCredencial: [],
        documentoCurp: [],
        generarCartaRenuncia: false,
        firmaAutografa: {},
      },
      rules: {
        required: [(value) => !!value || "El campo es obligatorio."],
        uploadFileSize: [
          (value) =>
            !value ||
            value.size < 2000000 ||
            "¡El documento debe ser menor a 2 MB!",
        ],
      },
    };
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 2000);
      this.loader = null;
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentEstatus() {
      return this.$localStorage.get("data_user");
    }
  },

  mounted() {
    document.title = "ASSA de México";
  },

  methods: {
    saveRegister() {
      if (this.$refs.form.validate()) {
        console.log("🚀 ~ file: FlightAttendant.vue:277 ~ saveRegister ~ this.$refs.form.validate():",this.$refs.form.validate());
      }
    },
    sendRegister(MSG) {
      console.log("🚀 ~ file: FlightAttendant.vue:284 ~ sendRegister ~ MSG:",MSG);
    },
    obtainSign(sender) {
      this.dataForm.firmaAutografa = sender.data.firmaAutografa;
    },
    obtainCurp(sender) {
      console.log("🚀 ~ file: FlightAttendant.vue:310 ~ obtainCurp ~ sender:",sender.data);
      //const answer = JSON.stringify(sender.data);
      this.dataForm.documentoIdentificacion = sender.data.documentoIdentificacion[0].content;
      this.dataForm.documentoCredencial = sender.data.documentoCredencial[0].content;
    },

    saveDocument() {
      var data = {
        usuario: {
          id: 2,
          usuario: "isramemo@gmail.com",
        },
        documento: {
          documento: 2,
          documentoTipo: "CURP",
          persona: null,
          archivoNombre: null,
        },
        file: {},
      };
      console.log("🚀 ~ file: FlightAttendant.vue:323 ~ saveDocument ~ data:",data);
    },
  },
};
</script>
<style></style>