import axios from "axios";
const API_URL = process.env.VUE_APP_ENDPOINT;
const contentType = "application/json";
const apiToken = process.env.VUE_APP_TOKEN_API;

const axiosConfig = {
  headers: {
    "Content-Type": contentType,
    Authorization: apiToken,
  },
};
class AuthService {
  login(user) {
    return axios
      .post(API_URL + "/authentication/login/",{
          usuario: user.username,
          contrasenia: user.password,
        },
        axiosConfig
      ).then((response) => {
        if (response.data.codigo === "1000") {
          localStorage.setItem("estatus", JSON.stringify(response.data.solicitud.estatus));
          localStorage.setItem("folio", JSON.stringify(response.data.solicitud.folio));
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("data_user", JSON.stringify(response.data));
          localStorage.setItem("data_ine",JSON.stringify(response.data.documentos[0]));
          localStorage.setItem("data_curp",JSON.stringify(response.data.documentos[1]));
          localStorage.setItem("data_credencial",JSON.stringify(response.data.documentos[2]));
          localStorage.setItem("data_firma",JSON.stringify(response.data.documentos[3]));
          localStorage.setItem("data_renuncia",JSON.stringify(response.data.documentos[4]));
          localStorage.setItem("data_solicitud",JSON.stringify(response.data.documentos[5]));
        }
        return response.data;
      }).catch((err) => {
        console.log("1)" + err.message);
        console.log("2)" + err.name);
        console.log("3)" + err.code);
        return err.message;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("data_user");
    localStorage.removeItem("data_ine");
    localStorage.removeItem("data_curp");
    localStorage.removeItem("data_credencial");
    localStorage.removeItem("data_firma");
    localStorage.removeItem("data_renuncia");
    localStorage.removeItem("data_solicitud");
  }
}
export default new AuthService();