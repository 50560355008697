import Vue from "vue";
import VueRouter from "vue-router";
//import HomeView from '../views/HomeView.vue'

/*
 * Area Publica
 */
import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import RegisterValidate from "@/views/auth/RegisterValidate.vue";
import RecoveryValidate from "@/views/auth/ValidateRecovery.vue";
import PasswordSet from "@/views/auth/PasswordSet.vue";
import PasswordReset from "@/views/auth/PasswordReset.vue";

import FlightAttendant from "@/views/FlightAttendant.vue";

Vue.use(VueRouter);

const routes = [
  //Authentication
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/validate-register",
    name: "RegisterValidate",
    component: RegisterValidate,
  },
  {
    path: "/validate-recovery",
    name: "RecoveryValidate",
    component: RecoveryValidate,
  },
  {
    path: "/password/set",
    name: "PasswordSet",
    component: PasswordSet,
  },
  {
    path: "/password/reset",
    name: "PasswordReset",
    component: PasswordReset,
  },

  /* FlightAttendant */
  {
    path: "/flight-attendant",
    name: "FlightAttendant",
    redirect: "/flight-attendant/personal-data",
    component: () =>
      import(
        /* webpackChunkName: "FlightAttendant" */ "@/views/FlightAttendant.vue"
      ),
    children: [
      {
        path: "/flight-attendant/personal-data",
        name: "FlightAttendantPersonalData",
        component: () =>
          import(
            /* webpackChunkName: "FlightAttendantPersonalData" */ "@/components/FlightAttendant/DatosPersonales"
          ),
      },
      {
        path: "/flight-attendant/documents",
        name: "FlightAttendantDocuments",
        component: () =>
          import(
            /* webpackChunkName: "FlightAttendantDocuments" */ "@/components/FlightAttendant/Documentos"
          ),
      },
      {
        path: "/flight-attendant/sign",
        name: "FlightAttendantFirma",
        component: () =>
          import(
            /* webpackChunkName: "FlightAttendantCartaRenuncia" */ "@/components/FlightAttendant/Firma"
          ),
      },
      {
        path: "/flight-attendant/resignation",
        name: "FlightAttendantCartaRenuncia",
        component: () =>
          import(
            /* webpackChunkName: "FlightAttendantCartaRenuncia" */ "@/components/FlightAttendant/CartaRenuncia"
          ),
      },
      {
        path: "/flight-attendant/thanks",
        name: "ThanksView",
        component: () =>
          import(
            /* webpackChunkName: "ThanksView" */ "@/components/FlightAttendant/Thanks"
          ),
      },
    ],
  },

  /* Pages */
  {
    path: "/cct/",
    name: "CctRegisterView",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/cct/RegisterView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL_CONTEXT,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/",
    "/register",
    "/validate-register",
    "/validate-recovery",
    "/password/set",
    "/password/reset",
  ];

  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    next("/");
  } else {
    next();
  }
});

export default router;
