<template>
  <section>
    <v-btn @click.prevent="logOut" text>
      <v-icon>mdi-logout</v-icon>
      Salir
    </v-btn>
  </section>
</template>
<script>
export default {
  name: "Menu",

  components: {},

  data: () => ({}),

  mounted() {},

  computed: {},

  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      localStorage.removeItem("data_user");
      localStorage.removeItem("documentos");
      localStorage.removeItem("user_session");
      localStorage.removeItem("data_ine");
      localStorage.removeItem("data_curp");
      localStorage.removeItem("data_credencial");
      localStorage.removeItem("data_firma");
      localStorage.removeItem("data_renuncia");
      localStorage.removeItem("data_solicitud");
      this.$router.go();
    },
  },
};
</script>
