<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <h1 class="display-2">Claves de Acceso</h1>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="4" md="6" sm="12">
        <GlobalAlerts
          v-if="setPassword.codigo === '1'"
          cfgColorBg="light-green darken-2"
          cfgIcon="mdi-check-all"
          cfgProminent="true"
          :cfgMessage="setPassword.mensaje"
        />

        <v-form v-if="setPassword.codigo !== '1'" ref="form" v-model="valid" lazy-validation>
          <v-text-field
            filled
            label="Contraseña"
            v-model="set.password"
            :rules="rules.password"
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showpass ? 'text' : 'password'"
            hint="Coloca al menos 8 caracteres"
            counter
            @click:append="showpass = !showpass"
          />
          <v-text-field
            filled
            label="Repetir contraseña"
            :rules="passwordConfirm"
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showpass ? 'text' : 'password'"
            hint="Coloca al menos 8 caracteres"
            counter
            @click:append="showpass = !showpass"
          />
          <div class="">
            <v-btn
              x-large
              color="indigo darken-4"
              dark
              @click="resetPassword()"
              class="float-right"
            >
              <v-icon dark left> mdi-content-save-check-outline </v-icon>
              guardar
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
    <v-row> </v-row>
  </v-container>
</template>

<script>
import GlobalAlerts from "@/components/global/_Alerts.vue";
import wsAuthentication from "@/services/authentication";

export default {
  name: "PasswordSet",

  components: {
    GlobalAlerts,
  },

  data() {
    return {
      set: {
        password: "",
      },
      loader: null,
      loading: false,
      valid: true,
      showpass: false,
      errorStatus: "",
      setPassword: {},
      rules: {
        password: [(v) => !!v || "La Contraseña es obligatoria"],
      },
    };
  },

  computed: {
    message() {
      var message = this.$route.query.message;
      return message;
    },
    passwordConfirm() {
      return [(v) => v === this.set.password || "Las contraseñas no coinciden"];
    },
  },

  mounted() {
    document.title = "Asignación de claves | SIASA | ASSA de México";
    //console.log("🚀 ~ file: Login.vue ~ line 123 ~ mounted ~ this.loggedIn", this.loggedIn)
  },

  methods: {
    resetPassword() {
      if (this.$refs.form.validate() === true) {
        let userSession = JSON.parse(this.$localStorage.get("user_session"));
        var data = {
          usuario: userSession.usuario,
          contrasenia: this.set.password,
          token: userSession.token
        };
        
        wsAuthentication.registerComplete(data).then(
          (response) => {
            this.setPassword = response.data;
            console.log(
              "🚀 ~ file: PasswordSet.vue:113 ~ resetPassword ~ this.setPassword:",
              this.setPassword
            );
            localStorage.clear();
            /*
            if (this.setPassword.codigo === "1") {
              this.$router.push({ name: "Login" });
            }
            */
          },
          (error) => {
            this.setPassword =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
  },
};
</script>
