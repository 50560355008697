<template>
  <v-container>
    <v-row class="mb-6">
      <v-col cols class="d-flex justify-center">
        <h2 class="display-3">
          SIASA <span class="blue-grey--text text--lighten-2">v1.0</span>
        </h2>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <h2 class="display-1">Iniciar Sesión</h2>
        <hr class="blue darken-4 mt-2 mb-6" />

        <GlobalAlerts
          v-if="messageStatus === 'ValidationError'"
          cfgColorBg="red darken-1"
          cfgIcon="mdi-skull-crossbones"
          cfgMessage="El usuario y/o la contraseña no coinciden."
        />

        <GlobalAlerts
          v-if="messageStatus === 'OperationInvalid'"
          cfgColorBg="orange darken-1"
          cfgIcon="mdi-information"
          cfgMessage="Operación inválida, debes colocar algun dato."
        />

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            filled
            label="Correo electrónico: "
            v-model="user.username"
            :rules="rules.email"
          />
          <v-text-field
            filled
            label="Contraseña"
            v-model="user.password"
            :rules="rules.password"
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showpass ? 'text' : 'password'"
            @click:append="showpass = !showpass"
            hint="Coloca al menos 8 caracteres"
            counter
          />

          <v-btn
            :loading="loading"
            x-large
            color="indigo darken-4"
            dark
            @click="accessLogin()"
            class="strong float-right"
          >
            <v-icon left> mdi-login </v-icon>
            INGRESAR
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <v-row>
      <v-col col md="12" class="d-flex justify-center">
        <v-btn :to="{ name: 'PasswordReset' }" text color="blue darken-2 ma-4">
          ¿Olvidaste tus datos de acceso?
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col col md="12" class="d-flex justify-center">
        <v-btn :to="{ name: 'Register' }" text color="red darken-2 ma-4">
          <v-icon left>mdi-newspaper-check</v-icon>
          Registrarse
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//Componentes
import GlobalAlerts from "@/components/global/_Alerts.vue";
//Models
import User from "@/models/user";
export default {
  name: "Login",
  components: {
    GlobalAlerts,
  },
  data() {
    return {
      user: new User("", ""),
      loader: null,
      loading: false,
      message: "",
      valid: true,
      showpass: false,
      messageStatus: "",
      accessOk: {},
      rules: {
        email: [
          (v) => !!v || "El correo electrónico obligatorio",
          (v) => /.+@.+/.test(v) || "Con es un correo valido",
        ],
        password: [(v) => !!v || "La contraseña es obligatoria."],
      },
    };
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 2000);
      this.loader = null;
    },
  },
  computed: {
    loggedIn() {
      //console.log('mounted');
      localStorage.removeItem("user");
      localStorage.removeItem("data_user");
      localStorage.removeItem("data_ine");
      localStorage.removeItem("data_curp");
      localStorage.removeItem("data_credencial");
      localStorage.removeItem("data_firma");
      localStorage.removeItem("data_renuncia");
      localStorage.removeItem("data_solicitud");
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    document.title = "ASSA de México";
    localStorage.removeItem("user");
    localStorage.removeItem("data_user");
    localStorage.removeItem("data_ine");
    localStorage.removeItem("data_curp");
    localStorage.removeItem("data_credencial");
    localStorage.removeItem("data_firma");
    localStorage.removeItem("data_renuncia");
    localStorage.removeItem("data_solicitud");
  },

  /*
  created() {
    if (this.loggedIn) {
      this.$router.push("/flight-attendant");
    }
  },
  */

  methods: {
    accessLogin() {
      this.$store.dispatch("auth/login", this.user).then(
        (response) => {
          switch (response.codigo) {
            case "01":
              break;
            case "02":
              break;
            case "0":
              this.messageStatus = "ValidationError";
              break;
            case "1000":
              this.messageStatus = "ValidationOk";
              this.$router.push("/flight-attendant");
              break;
            default:
              this.messageStatus = "OperationInvalid";
              console.log(
                "🚀 ~ file: Login.vue:149 ~ accessLogin ~ this.messageStatus:",
                this.messageStatus
              );
              break;
          }
        },
        (error) => {
          //this.loader = 'loading';
          console.log("--> " + error);
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.messageStatus = this.message.error.name;
        }
      );
      //}
    },
  },
};
</script>
