<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <h1 class="display-2">Validando cuenta</h1>
    </v-row>
    <v-row>
      <v-col>
        <GlobalAlerts
          v-if="validate.codigo === '1000'"
          cfgColorBg="light-green darken-2"
          cfgIcon="mdi-check-all"
          cfgProminent="true"
          :cfgMessage="validate.mensaje"
        />
        <GlobalAlerts
          v-if="validate.codigo === '200'"
          cfgColorBg="amber darken-3"
          cfgIcon="mdi-information"
          cfgProminent="true"
          :cfgMessage="validate.mensaje"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GlobalAlerts from "@/components/global/_Alerts.vue";
import wsAuthentication from "@/services/authentication";

export default {
  name: "RegisterValidate",

  components: {
    GlobalAlerts,
  },

  data() {
    return {
      validate: {},
    };
  },

  watch: {},

  computed: {
    tokenValidate() {
      var token = this.$route.query.t;
      console.log(
        "🚀 ~ file: RegisterValidate.vue:49 ~ tokenValidate ~ token:",
        token
      );
      return token;
    },
  },

  mounted() {
    document.title = "Validación | SIASA |ASSA de México";
    //this.$route;
    this.getToken();
  },

  methods: {
    getToken() {
      var data = {
        token: this.tokenValidate,
      };
      wsAuthentication.registerValidate(data).then(
        (response) => {
          this.validate = response.data;
          console.log(
            "🚀 ~ file: RegisterValidate.vue:67 ~ getToken ~ this.validate :",
            this.validate
          );

          if (this.validate.codigo === "200") {
            this.$router.push({
              name: "PasswordReset",
              query: { message: 200 },
            });
          }
          if (this.validate.codigo === "1000") {
            this.$localStorage.set(
              "user_session",
              JSON.stringify(this.validate)
            );
            this.$router.push({ name: "PasswordSet" });
          }
        },
        (error) => {
          this.validate =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
