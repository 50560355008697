<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <h1 class="display-2">Recuperar Acceso</h1>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col col xl="4" md="6" sm="12">
        <GlobalAlerts
          v-if="message === 200"
          cfgColorBg="amber darken-3"
          cfgIcon="mdi-information"
          cfgProminent="true"
          cfgMessage="
            Este enlace ya fue usado, para poder acceder a la cuenta intente recuperar las contraseña.
            En caso de tener problemas para acceder enviar un correo a: siasa@assa.mx. "
        />

        <GlobalAlerts
          v-if="validate.codigo === '3000'"
          cfgColorBg="amber darken-3"
          cfgIcon="mdi-information"
          cfgProminent="true"
          cfgMessage="Esta solicitud no puede ser procesada"
        />

        <GlobalAlerts
          v-if="validate.codigo === '1'"
          cfgColorBg="light-green darken-2"
          cfgIcon="mdi-check-all"
          cfgProminent="true"
          :cfgMessage="validate.mensaje"
        />

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            filled
            label="Correo electrónico"
            v-model="user.email"
            :rules="rules.email"
          />
          <div class="">
            <v-btn v-if="validate.codigo !== '1'"
              x-large 
              color="indigo darken-4"
              dark
              @click="resetPassword()"
              class="float-right" 
            >
              <v-icon dark left> mdi-email </v-icon>
              Enviar
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
    <v-row> </v-row>
  </v-container>
</template>

<script>
import GlobalAlerts from "@/components/global/_Alerts.vue";
import wsAuthentication from "@/services/authentication";

export default {
  name: "Login",

  components: {
    GlobalAlerts,
  },

  data() {
    return {
      valid: true,
      showpass: false,
      errorStatus: "",
      validate: {},
      user: {
        email: "",
      },
      loading: "",
      rules: {
        email: [
          (v) => !!v || "El correo electrónico obligatorio",
          (v) => /.+@.+/.test(v) || "Con es un correo valido",
        ],
        password: [(v) => !!v || "La Contraseña es obligatoria"],
      },
    };
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 2000);

      this.loader = null;
    },
  },

  computed: {
    message() {
      var message = this.$route.query.message;
      return message;
    },
  },

  mounted() {
    //document.title = 'ASSA de México'
    //console.log("🚀 ~ file: Login.vue ~ line 123 ~ mounted ~ this.loggedIn", this.loggedIn)
    this.$localStorage.remove("user_session");
  },

  methods: {
    resetPassword() {
      if (this.$refs.form.validate() === true) {
        var data = {
          correoElectronico: this.user.email,
        };
        wsAuthentication.passwordRecovery(data).then(
          (response) => {
            this.validate = response.data;
            if(this.validate.codigo === '3000'){
              this.validate.codigo = '1';
            }
            console.log(
              "🚀 ~ file: PasswordReset.vue:107 ~ resetPassword ~ this.validate:",
              this.validate
            );
            /*
            if (this.validate.codigo === "200") {
              this.$router.push({
                name: "PasswordReset",
                query: { message: 200 },
              });
            }
            if (this.validate.codigo === "1000") {
              this.$localStorage.set(
                "user_session",
                JSON.stringify(this.validate)
              );
              this.$router.push({ name: "PasswordSet" });
            }
            */
          },
          (error) => {
            this.validate =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
  },
};
</script>
