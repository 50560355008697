<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <h1 class="display-2">Registro</h1>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="5" md="6" sm="12">
        <p class="text-justify">
          Al registrarte recibiras un correo electrónico para continuar con el
          proceso. No registrar correo institucional
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col col xl="5" md="6" sm="12">
        <GlobalAlerts
          v-if="register.codigo === '1'"
          cfgColorBg="light-green darken-2"
          cfgIcon="mdi-check-all"
          cfgProminent="true"
          :cfgMessage="register.mensaje"
        />
        <GlobalAlerts
          v-if="register.codigo === '3'"
          cfgColorBg="amber darken-3"
          cfgIcon="mdi-information"
          cfgProminent="true"
          :cfgMessage="register.mensaje"
        />

        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          v-if="register.codigo != '1'"
        >
          <v-text-field
            filled
            label="Nombre(s)"
            v-model="dataForm.nombre"
            :rules="rules.required"
          />
          <v-text-field
            filled
            label="Primer apellido"
            v-model="dataForm.primerApellido"
            :rules="rules.required"
          />
          <v-text-field
            filled
            label="Segundo apellido"
            v-model="dataForm.segundoApellido"
            :rules="rules.required"
          />
          <v-text-field
            filled
            label="CURP" :counter="18" :maxlength="18"
            v-model="dataForm.curp" 
            :rules="rules.required"
          />
          <v-text-field
            filled
            label="Correo electrónico"
            v-model="dataForm.correoElectronico"
            :rules="rules.email"
          />
          <v-text-field
            filled
            label="Repite correo electrónico"
            :rules="emailConfirm"
          />

          <div class="">
            <v-btn
              x-large
              color="blue darken-4"
              dark
              class="float-right"
              @click="saveRegister()"
            >
              <v-icon dark> mdi-content-save-check-outline </v-icon>
              GUARDAR
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>

    <v-row class="mb-10">
      <pre></pre>
    </v-row>
  </v-container>
</template>

<script>
import GlobalAlerts from "@/components/global/_Alerts.vue";
import wsAuthentication from "@/services/authentication";

export default {
  name: "Register",

  components: {
    GlobalAlerts,
  },

  data() {
    return {
      loader: null,
      loading: false,
      message: "",
      valid: true,
      showpass: false,
      errorStatus: "",

      register: {},

      dataForm: {
        nombre: "",
        primerApellido: "",
        segundoApellido: "",
        curp: "",
        correoElectronico: "",
      },

      rules: {
        required: [(value) => !!value || "El dato es obligatorio."],
        counter: value => value.length === this.losnummerLength || `solo se permiten ${this.losnummerLength} caracteres.`,
        email: [
          (v) => !!v || "El correo electrónico obligatorio",
          (v) => /.+@.+/.test(v) || "Con es un correo valido",
        ],
        password: [(v) => !!v || "La Contraseña es obligatoria"],
      },
    };
  },

  watch: {},

  computed: {
    emailConfirm() {
      return [
        (v) =>
          v === this.dataForm.correoElectronico || "Los correos no coinciden",
      ];
    },
  },

  mounted() {
    document.title = "ASSA de México";
    //console.log("🚀 ~ file: Login.vue ~ line 123 ~ mounted ~ this.loggedIn", this.loggedIn)
  },

  methods: {
    validar() {
      this.$refs.form.validate();
      console.log(
        "🚀 ~ file: Register.vue:158 ~ validar ~ this.$refs.form.validate():",
        this.$refs.form.validate()
      );
    },

    saveRegister() {
      if (this.$refs.form.validate()) {
        console.log(
          "🚀 ~ file: Register.vue:157 ~ saveRegister ~ this.$refs.form.validate():",
          this.$refs.form.validate()
        );
        var data = {
          nombre: this.dataForm.nombre,
          primerApellido: this.dataForm.primerApellido,
          segundoApellido: this.dataForm.segundoApellido,
          curp: this.dataForm.curp,
          correoElectronico: this.dataForm.correoElectronico,
        };
        console.log("🚀 ~ file: Register.vue:164 ~ saveRegister ~ data:", data);

        wsAuthentication.registerFlightAssistant(data).then(
          (response) => {
            this.register = response.data;
            console.log(
              "🚀 ~ file: Register.vue:168 ~ saveAnswer ~ register:",
              response
            );
          },
          (error) => {
            this.currentAnswer =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
  },
};
</script>
